import { ParameterContext } from '@contexts/parameters'
import { useLocationSearchParams } from '@hooks/use-location-search-params'
import { type ParsedQuery } from 'query-string'
import React, {
  type FC,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react'

export const SupportedQueryParams = {
  UTMContent: 'utm_content',
  UTMMedium: 'utm_medium',
  UTMCampaign: 'utm_campaign',
  UTMSource: 'utm_source',
  Origin: 'origine',
  Iframe: 'iframe',
}

export const ParametersProvider: FC<PropsWithChildren> = ({ children }) => {
  const searchParams = useLocationSearchParams()
  const [parameters, setParameters] = useState<ParsedQuery>({})

  // Any new supported location search params update parameter map existing value
  useEffect(() => {
    const newParams: ParsedQuery = {}
    for (const name of Object.values(SupportedQueryParams)) {
      const value = searchParams[name]
      if (value && parameters[name] !== value) {
        newParams[name] = value
      }
    }

    if (Object.entries(newParams).length > 0) {
      setParameters((previousParameters) => ({
        ...previousParameters,
        ...newParams,
      }))
    }
  }, [searchParams, parameters])

  return (
    <ParameterContext.Provider value={parameters}>
      {children}
    </ParameterContext.Provider>
  )
}
