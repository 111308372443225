import {
  type StaticAssetProps,
  StaticAssetsContext,
} from '@contexts/static-assets'
import { graphql, useStaticQuery } from 'gatsby'
import React, { type FC, type PropsWithChildren, useMemo } from 'react'

export const StaticAssetTag = 'scopeWebsite'

export const StaticAssetsProvider: FC<PropsWithChildren> = ({ children }) => {
  const data = useStaticQuery<Queries.StaticAssetsQuery>(graphql`
    query StaticAssets {
      staticAssets: allContentfulAsset(
        filter: {
          metadata: {
            tags: { elemMatch: { contentful_id: { eq: "scopeWebsite" } } }
          }
        }
      ) {
        nodes {
          ...ContentfulDefaultAsset
        }
      }
    }
  `)

  const staticAssets = useMemo(() => {
    const result: Utils.Object<StaticAssetProps> = {}
    for (const node of data.staticAssets.nodes) {
      if (node.title) {
        result[node.title] = node
      }
    }

    return result
  }, [data])

  return (
    <StaticAssetsContext.Provider value={staticAssets}>
      {children}
    </StaticAssetsContext.Provider>
  )
}
