import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import { type GatsbySSR } from 'gatsby'
import React from 'react'

type PluginOptions = {
  enableInspectorMode: boolean
  enableLiveUpdates: boolean
}

export const wrapRootElement: GatsbySSR['wrapRootElement'] = (
  { element },
  pluginOptions
) => {
  const { enableInspectorMode = false, enableLiveUpdates = false } =
    pluginOptions as unknown as PluginOptions

  if (enableInspectorMode || enableLiveUpdates) {
    return (
      <ContentfulLivePreviewProvider
        enableInspectorMode={enableInspectorMode}
        enableLiveUpdates={enableLiveUpdates}
        locale="fr-FR"
      >
        {element}
      </ContentfulLivePreviewProvider>
    )
  }

  return element
}
