// Fixme any when tracking move to Hoc & plugin (for each type of pages)
import { TrackingContext } from '@contexts/tracking'
import React, {
  type FC,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import TC_Wrapper from 'react-tag-commander'

export const TrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [wrapper, setWrapper] = useState<Utils.Nullable<TC_Wrapper>>(null)
  const wrapperInstance = TC_Wrapper.getInstance()

  // WrapperInstance.setDebug(true)

  useEffect(() => {
    let loop: ReturnType<typeof setTimeout> | undefined = null
    Promise.all([
      wrapperInstance.addContainer(
        'container_head',
        `${process.env.GATSBY_TAGCO_URL}/v1.js`,
        'head'
      ),
      wrapperInstance.addContainer(
        'container_body',
        `${process.env.GATSBY_TAGCO_URL}/v3.js`,
        'body'
      ),
    ])
      .then(() => {
        setWrapper(wrapperInstance)

        loop = setInterval(() => {
          // Cookie-parameters button
          const selectorPrivacy = document.querySelectorAll(
            '[id^=cookie-parameters]:not(.behavior-added)'
          )

          for (const selectorPrivacyItem of selectorPrivacy) {
            selectorPrivacyItem.classList.add('behavior-added')
            selectorPrivacyItem.addEventListener('click', () => {
              // @ts-expect-error
              window.tC.log(
                'Event Listener TagCommander - Click Cookies Parameters'
              )
              // @ts-expect-error
              window.tC.privacyCenter.showPrivacyCenter()
            })
          }
        }, 500)
      })
      .catch((error) => {
        console.error(error)
        setWrapper(null)
      })

    return () => {
      if (loop) {
        clearInterval(loop)
      }

      wrapperInstance.removeContainer('container_head')
      wrapperInstance.removeContainer('container_body')
      setWrapper(null)
    }
  }, [])

  return (
    <TrackingContext.Provider value={wrapper}>
      {children}
    </TrackingContext.Provider>
  )
}
