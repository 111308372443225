import { animateScroll as scroll } from 'react-scroll'

import {
  blogTableOfContentsId,
  navigationBarId,
} from '../components/layouts/navbar/constants'

const isNotInsideIframeVega = () => {
  try {
    return window.location === window.parent.location
  } catch (err) {
    return false
  }
}

export const scrollToHash = (hash: string) => {
  const isIframe = window.location.search.includes('iframe=true')

  const anchorElement = document.getElementById(hash.substring(1))
  const anchorTopPosition = anchorElement?.offsetTop || 0

  // Espace client fix
  if (isIframe) {
    parent.postMessage({ msg: 'scrollTop', top: anchorTopPosition }, '*')
    // Mobile Vega fix
    if (isNotInsideIframeVega()) {
      scroll.scrollTo(anchorTopPosition, { smooth: true })
    }
  } else {
    const navBar = document.getElementById(navigationBarId)
    const blogTOC = document.getElementById(blogTableOfContentsId)
    const navBarHeight = navBar?.offsetHeight || 0
    const blogTOCHeight = blogTOC?.offsetHeight || 0
    const stickyHeaderHeight = navBarHeight + blogTOCHeight

    const position = Math.max(anchorTopPosition - stickyHeaderHeight, 0)
    scroll.scrollTo(position, { smooth: true })
  }
}
