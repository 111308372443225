import { useLocation } from '@reach/router'
import { useEffect, useState } from 'react'

export const useIsIframe = () => {
  const location = useLocation()
  const [isIframe, setIsIframe] = useState(false)

  useEffect(() => {
    if (location) setIsIframe(location.search.includes('iframe=true'))
  }, [location])

  return isIframe
}
