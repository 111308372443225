export const PAGE_TYPE = {
  HOME: 'home',
  LANDING: 'landing', // NOT USED
  LANDING_CCO: 'landing-cco',
  FORM: 'form', // NOT USED
  CMS: 'cms',
  CMS_SPECIFIC: 'cms_specific',
  VALIDATION: 'validation', // NOT USED
  SEARCH: 'search',
  ERROR_404: '404',
  ERROR_500: '500', // NOT USED
  OBSOLETE_BROWSER: 'obsolete_browser', // NOT USED
  CONTACT: 'contact',
}

export const PAGE_TYPE_URL = {
  [PAGE_TYPE.HOME]: '/',
  [PAGE_TYPE.CMS_SPECIFIC]: '/offres-speciales',
  [PAGE_TYPE.SEARCH]: '/recherche',
  [PAGE_TYPE.ERROR_404]: '/404',
  [PAGE_TYPE.CONTACT]: '/contact',
}

export const PRODUCT = {
  COMPTE_BANCAIRE: 'compte-bancaire',
  BOURSE: 'bourse',
  ASSURANCE_VIE: 'assurance-vie',
  CREDIT_IMMOBILIER: 'credit-immobilier',
  PRET_PERSONNEL: 'pret-personnel',
  EPARGNE: 'epargne',
  LIVRET_A: 'livret-a',
  LIVRET_PLUS: 'livret-plus',
  LIVRET_DD: 'livret-developpement-durable',
  LIVRET_PLUS_ENFANT: 'livret-plus-enfant',
}

export const PRODUCT_TYPE_ID = {
  BANQUE: '1',
  BOURSE: '2',
  EPARGNE: '3',
  ASSURANCE_VIE: '4',
  ASSURANCE_AUTO: '5',
  MULTI_PRODUIT: '6',
  AUTRE: '7',
  LIVRET_A: '8',
  LIVRET_PLUS: '9',
  LIVRET_DD: '10',
  LIVRET_PLUS_ENFANT: '11',
  CREDIT_IMMOBILIER: '12',
  PRET_PERSONNEL: '13',
}

export const PRODUCT_TYPE_LABEL = {
  [PRODUCT_TYPE_ID.BANQUE]: 'banque',
  [PRODUCT_TYPE_ID.BOURSE]: 'bourse',
  [PRODUCT_TYPE_ID.EPARGNE]: 'epargne',
  [PRODUCT_TYPE_ID.LIVRET_A]: 'livret_a',
  [PRODUCT_TYPE_ID.LIVRET_DD]: 'ldds',
  [PRODUCT_TYPE_ID.LIVRET_PLUS]: 'livret_plus',
  [PRODUCT_TYPE_ID.LIVRET_PLUS_ENFANT]: 'livret_plus_enfant',
  [PRODUCT_TYPE_ID.CREDIT_IMMOBILIER]: 'credit_immobilier',
  [PRODUCT_TYPE_ID.PRET_PERSONNEL]: 'pret_personnel',
  [PRODUCT_TYPE_ID.ASSURANCE_VIE]: 'assurance_vie',
  [PRODUCT_TYPE_ID.ASSURANCE_AUTO]: 'assurance_auto',
  [PRODUCT_TYPE_ID.MULTI_PRODUIT]: 'multi_produit',
  [PRODUCT_TYPE_ID.AUTRE]: 'autre',
}

export const PRODUCT_ID_TYPE_MAPPING = {
  [PRODUCT.COMPTE_BANCAIRE]: PRODUCT_TYPE_ID.BANQUE,
  [PRODUCT.BOURSE]: PRODUCT_TYPE_ID.BOURSE,
  [PRODUCT.ASSURANCE_VIE]: PRODUCT_TYPE_ID.ASSURANCE_VIE,
  [PRODUCT.CREDIT_IMMOBILIER]: PRODUCT_TYPE_ID.CREDIT_IMMOBILIER,
  [PRODUCT.PRET_PERSONNEL]: PRODUCT_TYPE_ID.PRET_PERSONNEL,
  [PRODUCT.EPARGNE]: PRODUCT_TYPE_ID.EPARGNE,
  [PRODUCT.LIVRET_A]: PRODUCT_TYPE_ID.LIVRET_A,
  [PRODUCT.LIVRET_PLUS]: PRODUCT_TYPE_ID.LIVRET_PLUS,
  [PRODUCT.LIVRET_DD]: PRODUCT_TYPE_ID.LIVRET_DD,
  [PRODUCT.LIVRET_PLUS_ENFANT]: PRODUCT_TYPE_ID.LIVRET_PLUS_ENFANT,
  default: PRODUCT_TYPE_ID.MULTI_PRODUIT,
}

export const PRODUCT_URL_MAPPING: Record<string, string> = {
  '/compte-bancaire': PRODUCT.COMPTE_BANCAIRE,
  '/compte-bancaire-prive': PRODUCT.COMPTE_BANCAIRE,
  '/bourse': PRODUCT.BOURSE,
  '/assurance-vie': PRODUCT.ASSURANCE_VIE,
  '/credit-immobilier': PRODUCT.CREDIT_IMMOBILIER,
  '/pret-personnel': PRODUCT.PRET_PERSONNEL,
  '/pret-personnel-prive': PRODUCT.PRET_PERSONNEL,
  '/epargne': PRODUCT.EPARGNE,
  '/epargne/livret-a': PRODUCT.LIVRET_A,
  '/epargne/livret-plus': PRODUCT.LIVRET_PLUS,
  '/epargne/livret-developpement-durable': PRODUCT.LIVRET_DD,
  '/epargne/livret-plus-enfant': PRODUCT.LIVRET_PLUS_ENFANT,
}

export const ENV_TEMPLATE = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
}

export const ENV_TEMPLATE_LABEL = {
  [ENV_TEMPLATE[1]]: 'home',
  [ENV_TEMPLATE[2]]: 'information_produit',
  [ENV_TEMPLATE[3]]: 'demande_de_documentation',
  [ENV_TEMPLATE[6]]: 'annexes',
  [ENV_TEMPLATE[8]]: 'resultat_de_recherche',
}

export const ERROR_TYPE = {
  404: '404',
  500: '500',
}

export const EVENT_ACTION = {
  CLIC_ACTION: 'clic_action',
  CLIC_SORTANT: 'clic_sortant',
  CLIC_TELECHARGEMENT: 'clic_telechargement',
  CLIC_NAVIGATION: 'clic_navigation',
  CLIC_REDIRECTION: 'redirection',
  AFFICHAGE_POPIN: 'affichage_popin',
}

export const EVENT = {
  CLICK: 'click',
  PAGEVIEW: 'pageview',
  CONVERT: 'convert',
}

export const AMEX = 'amex'
export const AMEX_TYPE = {
  BLUE: 'blue',
  GREEN: 'green',
  GOLD: 'gold',
  PLATINUM: 'platinum',
}

export const AMEX_LINK_SUFFIX: Utils.Object<string> = {
  'https://www.americanexpress.com/fr/fortuneoblue': AMEX_TYPE.BLUE,
  'https://www.americanexpress.com/fr/9004': AMEX_TYPE.GREEN,
  'https://www.americanexpress.com/fr/6155': AMEX_TYPE.GOLD,
  'https://www.americanexpress.com/fr/6278': AMEX_TYPE.PLATINUM,
  '/compte-bancaire-american-express/carte-blue-prive': AMEX_TYPE.BLUE,
  '/compte-bancaire-american-express/carte-green-american-express-prive':
    AMEX_TYPE.GREEN,
  '/compte-bancaire-american-express/carte-gold-american-express-prive':
    AMEX_TYPE.GOLD,
  '/compte-bancaire-american-express/carte-platinum-american-express-prive':
    AMEX_TYPE.PLATINUM,
  'https://www.americanexpress.com/fr-fr/charge-cards/apply/personal/blue':
    AMEX_TYPE.BLUE,
}

export const AMEX_LINKS_WITH_SUFFIX = Object.keys(AMEX_LINK_SUFFIX)

export const AMEX_TRACKED_LINK = [
  'https://mabanque.fortuneo.fr/fr/prive/banque/compte-bancaire/carte-american-express-prive.jsp',
  'https://www.americanexpress.com/fr/assurance/assurances-incluses/carte-american-express/blue.html',
  '/compte-bancaire',
  ...AMEX_LINKS_WITH_SUFFIX,
]
