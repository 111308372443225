import { scrollToHash } from '@utils/scroll-to-hash'
import type { GatsbyBrowser } from 'gatsby'
import { animateScroll as scroll } from 'react-scroll'

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { hash } = location
  window.history.scrollRestoration = 'manual'

  if (hash) {
    scrollToHash(hash)
  } else if (prevRouterProps) {
    const isIframe = window.location.search.includes('iframe=true')

    if (isIframe) {
      // TODO: remove this fix espace client
      document.getElementById('___gatsby')?.scrollIntoView(true)
    } else {
      window.scrollTo(0, 0)
    }
  } else {
    const currentPositionY: number = getSavedScrollPosition(location)[1]
    scroll.scrollTo(currentPositionY)
  }

  return true
}
