import React from 'react'

export const onClientEntry = () => {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.PERFORMANCE_REPORTS === 'true'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
}
