import React, {
  createContext,
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type SetStateAction,
  useState,
} from 'react'

type ContentfulFootnoteSupModelFragment =
  Queries.ContentfulFootnoteSupFragment[]

type FootnotesContextProps = {
  footnotes: ContentfulFootnoteSupModelFragment
  setFootnotes: Dispatch<SetStateAction<ContentfulFootnoteSupModelFragment>>
}

export const FootnotesContext = createContext<FootnotesContextProps>({
  footnotes: [],
  setFootnotes() {
    console.error('You forgot to put the Footnotes provider')
  },
})

export const Footnotes: FC<PropsWithChildren> = ({ children }) => {
  const [footnotes, setFootnotes] =
    useState<ContentfulFootnoteSupModelFragment>([])

  return (
    <FootnotesContext.Provider value={{ footnotes, setFootnotes }}>
      {children}
    </FootnotesContext.Provider>
  )
}
