import { ContentType } from '@entries/content-types'

import pkgJson from '../../../../package.json'
import {
  ENV_TEMPLATE,
  ERROR_TYPE,
  PAGE_TYPE,
  PRODUCT_ID_TYPE_MAPPING,
  PRODUCT_URL_MAPPING,
} from '../constants'
import { getPageType, getProductTypeId } from '../utils'

type TrackingPageVars = {
  [key: string]: string
  url: string
  version_site: string
  type_produit: string
  nom_produit: string
  env_template: string
  page_nom: string
}

export const getTrackingPageVars = (
  pathname: string,
  data: any
): TrackingPageVars => {
  const { page, content } = data || {}
  const url = pathname
  const pageType = getPageType(url, page)
  const productId = getProductTypeId(url)
  const result: TrackingPageVars = {
    url,
    version_site: pkgJson.version,
    type_produit: productId,
    nom_produit: PRODUCT_URL_MAPPING[url] || '',
    env_template: ENV_TEMPLATE[6],
    page_nom: 'accueil',
    tags:
      content?.__typename === ContentType.ContentfulBlogArticle
        ? content.tags.map((tag: any) => tag.slug)
        : undefined,
  }

  if (pageType === PAGE_TYPE.HOME) {
    result.env_template = ENV_TEMPLATE[1]
  } else if (
    [PAGE_TYPE.LANDING, PAGE_TYPE.VALIDATION, PAGE_TYPE.FORM].includes(pageType)
  ) {
    result.env_template = ENV_TEMPLATE[3]
  } else if (pageType === PAGE_TYPE.LANDING_CCO) {
    result.env_template = ENV_TEMPLATE[4]
  } else if (pageType === PAGE_TYPE.SEARCH) {
    result.env_template = ENV_TEMPLATE[8]
  } else if (pageType === PAGE_TYPE.CONTACT) {
    result.env_template = ENV_TEMPLATE[9]
  } else if (productId !== PRODUCT_ID_TYPE_MAPPING.default) {
    result.env_template = ENV_TEMPLATE[2]
  }

  const paths = String(url)
    .split('/')
    .filter((elt) => elt !== '')
  if (paths && paths.length > 0) {
    for (let i = 0; i < paths.length - 1; i++) {
      result[`page_cat${i + 1}`] = paths[i]
    }

    result.page_nom = paths[paths.length - 1]
  }

  if (pageType === PAGE_TYPE.ERROR_404) {
    result.type_erreur = ERROR_TYPE[404]
  } else if (pageType === PAGE_TYPE.ERROR_500) {
    result.type_erreur = ERROR_TYPE[500]
  }

  return result
}
