import { nanoid } from 'nanoid'
import React, {
  type FC,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react'

import { TokenContext } from './token-context'
import { useIsIframe } from './use-is-iframe'

const domains = [
  'https://mabanque.fortuneo.fr',
  'https://preprod-mabanque.fortuneo.fr',
  'http://front-rec.fortuneo-r.cloud.arkea.com:8080',
]

type MessageEventData = {
  token: string
  msg: string
  requestId: string
}

export const TokenProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState('')
  const isIframe = useIsIframe()

  useEffect(() => {
    if (!isIframe) {
      return
    }

    const requestId = nanoid()
    const callback = (event: MessageEvent<MessageEventData>) => {
      if (
        event?.data?.msg === 'tokenResponse' &&
        requestId === event?.data?.requestId &&
        event?.origin &&
        domains.includes(event.origin)
      ) {
        const { token } = event.data
        if (token) {
          setToken(token)
        }
      }
    }

    window.addEventListener('message', callback)
    parent.postMessage({ msg: 'tokenRequest', requestId }, '*')

    return () => {
      window.removeEventListener('message', callback)
    }
  }, [isIframe, token])

  return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
}
