import { datadogRum } from '@datadog/browser-rum'
import { type GatsbyBrowser } from 'gatsby'

export type PluginOptions = {
  applicationId: string
  clientToken: string
  env: string
  version: string
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = (
  _,
  pluginOptions
) => {
  const { applicationId, clientToken, env, version } =
    pluginOptions as unknown as PluginOptions

  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: 'datadoghq.eu',
    service: 'website',
    env,
    version: version || 'local',
    sessionSampleRate: 15,
    sessionReplaySampleRate: 5,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
