/**
 * Converts the first character of string to upper case and the remaining to lower case.
 * @param s The string to capitalize.
 * @returns Returns the capitalized string.
 */
export const capitalize = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

/**
 * Converts string to snake case.
 * @param s The string to convert.
 * @returns Returns the snake cased string.
 */
export const snakeCase = (s: string) =>
  s
    .replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')

/**
 * Converts string to kebab case.
 * @param s The string to convert.
 * @returns Returns the kebab cased string.
 */
export const kebabCase = (s: string) =>
  s
    .replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('-')

/**
 * Check if value i a string.
 * @param s The value to check.
 * @returns Returns true if value is a string, else false.
 */
export const isString = <T>(s: T) => typeof s === 'string'

/**
 * Checks if value is a plain object.
 * @param obj The value to check.
 * @returns Returns true if value is a plain object, else false.
 */
export const isPlainObject = <T>(obj: T) =>
  obj?.constructor === Object || Object.getPrototypeOf(obj ?? 0) === null

/**
 * Creates an object composed of the picked object properties.
 * @param obj The source object.
 * @param keys The property names to pick, specified individually or in arrays.
 * @returns  Returns the new object.
 */
export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
  const ret: any = {}
  keys.forEach((key) => {
    ret[key] = obj[key]
  })
  return ret
}

/**
 * The opposite of pick method; this method creates an object composed of the own and inherited enumerable properties of object that are not omitted.
 * @param obj The source object.
 * @param keys Array of properties name to omit.
 * @returns Returns the new object.
 */
export const omit = <
  T extends Record<string, unknown>,
  K extends keyof T | string,
>(
  obj: T,
  keys: K[]
): Omit<T, K> =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key as K))
  ) as Omit<T, K>

/**
 * Creates an array of numbers
 * @param start The start of the range.
 * @param end The end of the range.
 * @param step The value to increment or decrement by. (default: 1)
 * @returns Returns a new range array.
 */
export const range = (start: number, end: number, step = 1) =>
  Array.from({ length: (end - start) / step }, (_, i) => start + i * step)

/**
 * Creates an array of elements split into groups the length of size. If collection can’t be split evenly, the final chunk will be the remaining elements.
 * @param arr The array to process.
 * @param size The length of each chunk.
 * @returns Returns the new array containing chunks.
 */
export const chunk = <T>(arr: readonly T[], size?: number): T[][] =>
  arr.reduce(
    (rows, key, index) =>
      (index % size === 0
        ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows,
    []
  )

/**
 * Creates an array of array values not included in the other provided arrays using SameValueZero for equality comparisons. The order and references of result values are determined by the first array.
 * @param arr1 The array to inspect.
 * @param arr2 The arrays of values to exclude.
 * @returns Returns the new array of filtered values.
 */
export const difference = <T>(arr1: T[], arr2: T[]) =>
  arr1.filter((x) => !arr2.includes(x))

/**
 * Creates an object composed of keys generated from the results of running each element of collection through iteratee. The corresponding value of each key is an array of the elements responsible for generating the key. The iteratee is invoked with one argument: (value).
 * @param arr The collection to iterate over.
 * @param keyProperty The function invoked per iteration.
 * @returns Returns the composed aggregate object.
 */
export const groupBy = <
  T extends Record<string, unknown>,
  K extends keyof T | string,
>(
  arr: readonly T[],
  keyProperty: K
): Record<string, readonly T[]> => {
  const result: Record<string, readonly T[]> = {}
  for (const item of arr) {
    const key = String(item[keyProperty])
    if (!result[key]) {
      result[key] = [] as readonly T[]
    }

    result[key] = [...result[key], item]
  }

  return result
}
