import { ConfigSiteProvider } from '@contexts/config-site'
import { GlobalStyle, ResetStyle } from '@fortuneo/ui'
import { TrackingProvider } from '@utils/tracking'
import { type GatsbySSR } from 'gatsby'
import React, { type FC, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { cacheExchange, Client, fetchExchange, Provider } from 'urql'

import { StaticAssetsProvider } from './components/static-asset/static-assets-provider'

const ExposeReact: FC = () => {
  useEffect(() => {
    window.React = React
    window.ReactDOM = ReactDOM
  }, [])

  return null
}

const client = new Client({
  url: process.env.GATSBY_SEARCH_API_URL,
  fetchOptions: {
    headers: {
      'x-api-key': process.env.GATSBY_SEARCH_API_KEY,
    },
  },
  fetch,
  exchanges: [cacheExchange, fetchExchange],
})

// Fixme should be GatsbySSR | GatsbyBrowser but crash cause of any type
export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <ConfigSiteProvider>
    <TrackingProvider>
      <Provider value={client}>
        <ResetStyle />
        <GlobalStyle />
        <ExposeReact />
        <StaticAssetsProvider>{element}</StaticAssetsProvider>
      </Provider>
    </TrackingProvider>
  </ConfigSiteProvider>
)
