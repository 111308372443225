import useRectObserver from '@hooks/use-rect-observer'
import React, {
  createContext,
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type RefObject,
  type SetStateAction,
  useEffect,
  useState,
} from 'react'

type Props = {
  height: number
  setNavbarRef: Dispatch<SetStateAction<RefObject<any> | undefined>>
}

export const NavbarHeightContext = createContext<Props>({
  height: 0,
  setNavbarRef() {
    console.error('You forgot to put the NavbarHeight provider')
  },
})

const DESKTOP_NAV_HEIGHT = 90

export const NavbarHeightProvider: FC<PropsWithChildren> = ({ children }) => {
  const [height, setHeight] = useState(DESKTOP_NAV_HEIGHT)
  const [navbarRef, setNavbarRef] = useState<RefObject<HTMLElement>>()
  const { height: rectHeight } = useRectObserver(navbarRef)

  useEffect(() => {
    setHeight(rectHeight)
  }, [rectHeight])

  return (
    <NavbarHeightContext.Provider
      value={{
        height,
        setNavbarRef,
      }}
    >
      {children}
    </NavbarHeightContext.Provider>
  )
}
