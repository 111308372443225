import { EVENT } from '@utils/tracking/constants'
import { getTrackingPageVars } from '@utils/tracking/provider/utils'
import { useEffect } from 'react'

import { useTrackingWrapper } from './use-tracking-wrapper'

export const useTrackPageLoad = (pathname: string, data: any) => {
  const wrapper = useTrackingWrapper()

  useEffect(() => {
    if (wrapper && pathname && data) {
      const tcVars = getTrackingPageVars(pathname, data)
      wrapper.triggerEvent(EVENT.PAGEVIEW, null, tcVars)
    }
  }, [wrapper, pathname, data])
}
