import { isBrowser } from '@utils/is-browser'
import { type RefObject, useEffect, useRef, useState } from 'react'

type UseReactObserver = (
  ref: RefObject<HTMLElement> | undefined
) => DOMRectReadOnly

const useRectObserver: UseReactObserver = (elRef) => {
  const [rect, setRect] = useState<DOMRectReadOnly>({} as DOMRectReadOnly)

  const observer = useRef(
    isBrowser
      ? new ResizeObserver((entries) => {
          const rect = entries[0].contentRect
          setRect(rect)
        })
      : null
  )

  useEffect(() => {
    if (!elRef?.current) {
      return undefined
    }

    const { current } = elRef

    observer.current?.observe(current)

    return () => {
      observer.current?.unobserve(current)
    }
  }, [elRef, observer])

  return rect
}

export default useRectObserver
