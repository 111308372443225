import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, type PropsWithChildren } from 'react'

export const ConfigSiteContext = createContext<
  Queries.ConfigSiteQuery['site'] | undefined
>(undefined)

export const ConfigSiteProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const configSite = useStaticQuery<Queries.ConfigSiteQuery>(graphql`
    fragment PopularQuestion on ContentfulFaqPage {
      title
      href: getHref
    }

    fragment ContentfulConfigSite on ContentfulSite {
      ...ContentfulEntry
      communicationBanner {
        ...ContentfulCommunicationBanner
      }
      communicationBannerContactPage {
        ...ContentfulCommunicationBanner
      }
      defaultImage {
        ...ContentfulDefaultAsset
      }
      popularQuestionsRef {
        ...PopularQuestion
      }
      ...ContentfulSiteSeo
      ...ContentfulSiteHeaderMenuDesktop
      ...ContentfulSiteHeaderMenuMobile
    }

    query ConfigSite {
      site: contentfulSite(contentful_id: { eq: "MKbBCDmv6LIExJlpcLJ9n" }) {
        ...ContentfulConfigSite
      }
    }
  `)
  return (
    <ConfigSiteContext.Provider value={configSite.site}>
      {children}
    </ConfigSiteContext.Provider>
  )
}
