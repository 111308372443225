import './src/styles/global.css'

import { isBrowser } from '@utils/is-browser'

export { onClientEntry } from './src/on-client-entry'
export { onRouteUpdate } from './src/on-route-update'
export { shouldUpdateScroll } from './src/should-update-scroll'
export { wrapPageElement } from './src/wrap-page-element'
export { wrapRootElement } from './src/wrap-root-element'

function setCookie(cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

if (isBrowser) {
  setCookie('market-subid', '', 0)
  setCookie('life-insurance-subid', '', 0)
  setCookie('current-account-subid', '', 0)
  setCookie('savings-account-subid', '', 0)
}
